import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiButton,
    EuiSuperSelect,
    EuiLoadingContent,
    EuiFlexGroup,
} from '@elastic/eui';
import { Controller, FormProvider } from 'react-hook-form';
import * as types from '../../../../../reduxStore/types/menu';
import { isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CategoryVisibility from './CategoryVisibility';
import { FormGroupDescription } from '../../../../../components/formGroupDescription/formGroupDescription';
import DeleteModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/deleteModal';
import SwitchField from '../../../../../components/Form/SwitchField';
import API from '../../../../../api/axios/API';
import CategoryImage from './CategoryImage';
import { onDeleteCategory, positionOptions } from './Utils/utils';
import CategoryBasicDetails from './CategoryBasicDetails';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../../utils/toasts';
import { fetchCategoryData } from './Api/fetchCategoryData';
import { MainLayout } from '../../../../../components/layout/mainLayout';
import useFormActionsHandler from '../../../../../hooks/useFormActionsHandler';

const CategoryCreateEditPage = () => {
    const editCategory = useSelector((state) => state.menu.editCategory);
    const addCategory = useSelector((state) => state.menu.addCategory);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const showDeleteConfirmationModal = useSelector((state) => state.menu.showDeleteConfirmationModal);
    const selectedCategoryId = useParams()?.categoryId;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});

    const loadingArray = Array.from({ length: 5 }, () => Math.floor(Math.random() * (5 - 2 + 1)) + 2);

    useEffect(() => {
        if (selectedCategoryId) {
            setIsCategoryLoading(true);
            dispatch(fetchCategoryData(selectedCategoryId)).finally(() => setIsCategoryLoading(false));
        }

        return () => {
            dispatch({
                type: types.SET_ADD_CATEGORY_ID,
                payload: {
                    addCategory: null,
                },
            });
            dispatch({
                type: types.EDIT_CATEGORY,
                payload: {
                    editCategory: {},
                },
            });
        };
    }, [selectedCategoryId, dispatch]);

    const updateFormValues = useCallback(
        (data) => {
            if (!isEmpty(data)) {
                let defaultValueObject = {};
                for (const element of data?.ordering_modes) {
                    defaultValueObject[`mode_${element.restaurant_ordering_mode_id}`] = true;
                }
                return {
                    ...data,
                    hide_category_tile_details: data?.hide_category_tile_details,
                    upload_image: {
                        id: data?.category_image_url?.id,
                        src: data?.category_image_url?.image_url,
                        image_url: data?.category_image_url?.image_url,
                        result: null,
                    },
                    editCategory: {
                        theme: data?.theme,
                        ordering_modes: data?.ordering_modes,
                        category_image_url: data?.category_image_url,
                        ...defaultValueObject,
                    },
                };
            } else {
                let obj = {
                    hidden: '',
                    hide_category_tile_details: '',
                    internal_name: '',
                    size: '',
                    upload_image: { id: '', src: '', image_url: '', result: null },
                    tile_details_position: '',
                    gradient_position: '',
                };
                availableLanguages.forEach((item) => {
                    obj['description' + item.language_id] = '';
                    obj['title' + item.language_id] = '';
                    obj['size' + item.language_id] = '';
                    obj['serves' + item.language_id] = '';
                });
                return obj;
            }
        },
        [availableLanguages]
    );

    useEffect(() => {
        setDefaultValues(updateFormValues(editCategory));
    }, [editCategory]);

    const onFormSaveApi = useCallback(
        async (data) => {
            let titles = {};
            let descriptions = {};
            let sizes = {};
            let serves = {};

            availableLanguages.map((language) => {
                titles[language.language_id] = data[`title${language.language_id}`];
                descriptions[language.language_id] = data[`description${language.language_id}`];
                sizes[language.language_id] = data[`size${language.language_id}`];
                serves[language.language_id] = data[`serves${language.language_id}`];
                return null;
            });
            let translationObject = {
                title: titles,
                description: descriptions,
                size: sizes,
                serves: serves,
            };

            let response = null;
            if (!selectedCategoryId) {
                response = await handleCategoryAdditionAction(data, translationObject);
            } else {
                response = await handleCategoryEditAction(data, translationObject);
            }

            return response;
        },
        [availableLanguages, selectedCategoryId]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    const handleCategoryEditAction = async (data, translationObject) => {
        let res;
        try {
            res = await API.put(`/restaurants/:restaurantId/categories/${data?.id ? data.id : editCategory.id}`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data.hide_category_tile_details,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: data.parent_id ? data.parent_id : null,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: data.editCategory?.ordering_modes ? data.editCategory.ordering_modes : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });

            history.goBack();

            toastSuccessMessage('Category successfully edited', dispatch);
        } else {
            apiResponseErrors(res);
        }

        return res;
    };

    const handleCategoryAdditionAction = async (data, translationObject) => {
        let res;
        try {
            res = await API.post(`/restaurants/:restaurantId/categories`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data?.hide_category_tile_details ? data.hide_category_tile_details : 0,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: addCategory,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: data.editCategory?.ordering_modes ? data.editCategory.ordering_modes : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });

            history.goBack();

            toastSuccessMessage('Category successfully added', dispatch);
        } else {
            apiResponseErrors(res);
        }

        return res;
    };

    const apiResponseErrors = useCallback(
        (res) => {
            if (!isEmpty(res?.errors?.category_image_id)) {
                toastsErrorMessage('Please Select Image', dispatch);
            } else if (!isEmpty(res?.errors?.internal_name)) {
                toastsErrorMessage(res.errors.internal_name, dispatch);
            } else {
                toastsErrorMessage('Failed to edit category', dispatch);
            }
        },
        [dispatch]
    );

    return (
        <>
            <MainLayout title='Category' historyBack={true}>
                {showDeleteConfirmationModal ? <DeleteModal /> : null}

                {isCategoryLoading ? (
                    <>
                        {loadingArray.map((loadingLine, index) => {
                            return (
                                <React.Fragment key={index.toString() + loadingLine.toString()}>
                                    <EuiFlexGroup>
                                        <EuiFlexItem grow={4}>
                                            <EuiLoadingContent lines={2} />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={6}>
                                            <EuiLoadingContent lines={loadingLine} />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <EuiSpacer />
                                </React.Fragment>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {selectedCategoryId ? (
                            <EuiFlexItem style={{ alignItems: 'flex-end' }}>
                                <EuiButton
                                    onClick={() => onDeleteCategory(dispatch, selectedCategoryId)}
                                    style={{ width: '200px' }}
                                    size='s'
                                    iconType='trash'
                                    fill
                                    color='danger'
                                >
                                    Delete
                                </EuiButton>
                            </EuiFlexItem>
                        ) : null}
                        <FormProvider {...methods}>
                            <EuiForm component='form'>
                                <CategoryImage />

                                <CategoryBasicDetails />

                                {!addCategory ? (
                                    <>
                                        <FormGroupDescription title='Tile Details Position'>
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiSuperSelect
                                                            options={positionOptions}
                                                            valueOfSelected={field.value}
                                                            onChange={(value) => {
                                                                setValue('tile_details_position', value, {
                                                                    shouldDirty: true,
                                                                });
                                                                setValue('gradient_position', value, {
                                                                    shouldDirty: true,
                                                                });
                                                            }}
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                name='tile_details_position'
                                            />
                                        </FormGroupDescription>
                                        <FormGroupDescription title='Gradient Position'>
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiSuperSelect
                                                            options={positionOptions}
                                                            valueOfSelected={field.value}
                                                            onChange={field.onChange}
                                                            disabled
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                name='gradient_position'
                                            />
                                        </FormGroupDescription>

                                        <FormGroupDescription title='Theme'>
                                            <EuiFlexItem>
                                                <Controller
                                                    render={({ field }) => {
                                                        return (
                                                            <EuiFormRow
                                                                label={'Select Theme'}
                                                                isInvalid={errors['theme']}
                                                                error={
                                                                    errors['theme']
                                                                        ? errors['Select Theme'].message
                                                                        : ''
                                                                }
                                                                fullWidth={true}
                                                            >
                                                                <EuiSuperSelect
                                                                    options={[
                                                                        {
                                                                            value: 'full_size_image',
                                                                            inputDisplay: 'Full Size Image',
                                                                        },
                                                                        {
                                                                            value: 'square_image',
                                                                            inputDisplay: 'Square Image',
                                                                        },
                                                                    ]}
                                                                    valueOfSelected={field.value}
                                                                    onChange={field.onChange}
                                                                />
                                                            </EuiFormRow>
                                                        );
                                                    }}
                                                    name={'editCategory.theme'}
                                                    control={control}
                                                    fullWidth
                                                />
                                            </EuiFlexItem>
                                            {<EuiSpacer />}
                                        </FormGroupDescription>

                                        <FormGroupDescription title='Category Visibility'>
                                            <CategoryVisibility control={control} setValue={setValue} />
                                        </FormGroupDescription>
                                    </>
                                ) : (
                                    <FormGroupDescription title='Category Visibility'>
                                        <CategoryVisibility control={control} setValue={setValue} />
                                    </FormGroupDescription>
                                )}

                                <FormGroupDescription title={'Hidden category'}>
                                    <SwitchField
                                        name={'hidden'}
                                        defaultValue={watch('editCategory.hidden') ? true : false}
                                        control={control}
                                        setValue={setValue}
                                        label=''
                                    />
                                </FormGroupDescription>
                                {!addCategory ? (
                                    <FormGroupDescription title='Hide Category tile'>
                                        <SwitchField
                                            name={'hide_category_tile_details'}
                                            defaultValue={
                                                watch('editCategory.hide_category_tile_details') ? true : false
                                            }
                                            control={control}
                                            setValue={setValue}
                                            label=''
                                        />
                                    </FormGroupDescription>
                                ) : null}
                            </EuiForm>
                        </FormProvider>
                    </>
                )}
            </MainLayout>
        </>
    );
};

export default React.memo(CategoryCreateEditPage);
