import React, { useCallback } from 'react';
import { EuiForm, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const OtherSettings = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        return {
            enable_auto_accept_order: data.settings.enable_auto_accept_order,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('other_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, watch, setValue } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.auto_accept?.display_name}
                description={restaurantDescriptions?.auto_accept?.description}
            >
                <ButtonGroup
                    idSelected={watch('enable_auto_accept_order') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='enable_auto_accept_order'
                    watch={watch}
                    label={'Enable auto accept order'}
                />
            </FormGroupDescription>
            <EuiSpacer />
        </EuiForm>
    );
};

export default OtherSettings;
